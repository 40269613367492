import React, { useEffect } from 'react'
import { PopularCourse } from './popularCourse';
import Footer from '../home/footer';

export function CoursesPage() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='w-full text-center min-h-screen relative pt-[60px] overflow-hidden '>
                <div className='bg-pumpkinToast-500 mt-[60px] w-[400px] h-[400px] absolute blur-[160px] z-0 top-[100px] '></div>
                <div className='bg-pumpkinToast-500 mt-[60px] w-[400px] h-[400px] absolute blur-[160px] z-0 top-[150vh] right-0 '></div>
                <PopularCourse />
                {/* <CouseCategory /> */}
                {/* <div className=' flex flex-col gap-32 pt-8 pb-36'>
                <GetIdCard />
                <StaffTraining />
            </div> */}
                {/* <Success /> */}
            </div>
            <Footer />
        </>
    )
}
