import React, { useEffect } from 'react'

export  function LibraryMain() {

    useEffect(() => {
          window.scrollTo(0, 0);
        }, []);
        
  return (
    <div className='w-full pt-[60px]'>
        <h1 className='md:text-3xl text-xl text-[#2a1273] py-10 text-center font-AndoraModernSerif'>Library</h1>
    </div>
  )
}
