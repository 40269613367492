export const AboutUsSection = () => {
    return (
        <div 
        className="bg-gray-50 md:py-16 py-10 text-left w-full flex justify-center relative"
         id="about_us_section"
        >
            <div 
                className="absolute inset-0 z-0 opacity-65"
                style={{
                    backgroundImage: "url('/assets/good_image.png')",
                    backgroundPosition:'top center'
                 }}
            ></div>
            <div className="mx-auto max-w-7xl items-center z-10">



                {/* Right Section: Text (spanning 2 cols) */}
                <div className="md:col-span-2 md:px-20  px-5">
                    <div className="mb-4">
                        <button className="  px-4 py-1 bg-[#daa658]/10 text-[#daa658] font-bold rounded hover:bg-opacity-80 round-[16px]">
                            About Us
                        </button>
                    </div>
                    <h2 className="font-AndoraModernSerif text-2xl text-white md:text-4xl font-extrabold ">
                        DarulHadees
                    </h2>
                    <div className="text-white md:text-base text-sm mt-6 space-y-2">
                        &emsp;&emsp;ഇസ് ലാമിൻ്റെ വിശ്വാസ, ആചാര, അനുഷ്ഠാന പരമായ മുഴുവൻ നിർദ്ദേശങ്ങളും നിലപാടുകളും ഇലാഹീ വെളിപാടുകളിൽ അധിഷ്ഠിതമാണ്. ഇതര ദർശന-പ്രത്യയശാസ്ത്രങ്ങളിൽ നിന്ന് ഇസ്ലാമിനെ വിഭിന്നമാക്കുന്നത് മനുഷ്യ കൈകടത്തലുകൾക്ക് വിധേയമാകാത്ത അതിൻറെ പ്രമാണങ്ങളാണ്.
                        ഇസ്ലാമിക പ്രമാണങ്ങളിൽ പ്രാധാന്യമേറെയുള്ളതാണ് ഹദീസുകൾ. മനുഷ്യജീവിതത്തിന്റെ ആദ്യാന്തം വിശകലനം ചെയ്യുന്നതിലും പ്രാവർത്തികമാക്കുന്നതിലും തിരുനബി ﷺ യുടെ സുന്നത്തിന് അവിഭാജ്യ സ്ഥാനമുണ്ട്.
                        <br /><br />&emsp;&emsp;മുത്ത്നബിﷺ തങ്ങളുടെ വാക്കുകളെയും പ്രവൃത്തികളെയും അനുവാദങ്ങളെയും അനുധാവനം ചെയ്യുന്നത് ഉത്തമ സാമൂഹിക ജീവിയാകാൻ വ്യക്തിയെ സഹായിക്കുമെന്നതിൽ തർക്കമില്ല. പാരസ്പര്യത്തിലൂന്നിയ ജീവിത വ്യവഹാരങ്ങളിൽ ഹദീസിൻ്റെ പ്രയോഗവൽക്കരണം വിശ്വാസിക്ക് ഇരുലോക വിജയ നിദാനമാകും.

                        അനുകൂലമായും പ്രതികൂലമായും ഇസ്‌ലാം പഠിക്കുന്നവർ പ്രധാനമായും ഹദീസുകളെയാണ് ആശ്രയിക്കുന്നത്. വിരോധികൾ വിമർശനായുധമാക്കുന്നതും ഹദീസുകളെ തന്നെ.
                        ഹദീസുകളിൽ സംശയം  ജനിപ്പിക്കുക വഴി മുസ്ലിം സമൂഹത്തിൽ ചേരിതിരിവുകുകൾ സൃഷ്ടിക്കാനും ശ്രമങ്ങൾ നടന്നുകൊണ്ടിരിക്കുന്നു. അത്തരം ചിന്തകളെ പ്രമാണബദ്ധമായി വിലയിരുത്തുകയും, സംശയങ്ങൾക്ക് നിവാരണം നൽകുകയും, ധൈഷണികവും, പ്രാമാണികവുമായ ഹദീസ് പഠനത്തിന് അവസരം ഒരുക്കുകയും ചെയ്യണം.
                        <br /><br />&emsp;&emsp;വ്യവസ്ഥാപിത ഹദീസ് പഠനത്തിനൊരു കേന്ദ്രം, അതാണ് ദാറുൽ ഹദീസ് ഇസ്‌ലാമിക് റിസർച്ച് സെൻ്റർ. ഫെല്ലോഷിപ്പോടു കൂടെ ഹദീസ് ഗവേഷണ പഠനം, സാധാരണക്കാർക്ക് ഹദീസ് പഠനം സാധ്യമാക്കാൻ ഓഫ് ലൈൻ ഓൺലൈൻ ക്ലാസുകൾ, ഹദീസിൻ്റെ അടിസ്ഥാന ചർച്ചാ പഠനങ്ങൾ, സെമിനാറുകൾ, ഹദീസ് ദുർവ്യാഖ്യാനങ്ങൾക്ക് പ്രതിരോധമൊരുക്കൽ, അറബി ഇംഗ്ലീഷ് മലയാളം ഭാഷകളിൽ ഗ്രന്ഥ രചനകൾ, ബഹുജന മാധ്യമങ്ങളെ ഹദീസ് പഠന വേദിയാക്കി മാറ്റൽ എന്നിവ സ്ഥാപനം ലക്ഷ്യം വെക്കുന്നു .......
                    </div>
                </div>
            </div>
        </div>
    );
};