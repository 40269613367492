import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from '../home/footer';

const ExpertCards = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openImage = (index) => {
    setSelectedImageIndex(index);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
  };

  const navigateImages = (direction) => {
    if (selectedImageIndex !== null) {
      const newIndex = (selectedImageIndex + direction + experts.length) % experts.length;
      setSelectedImageIndex(newIndex);
    }
  };

  const handleKeyDown = (event) => {
    if (selectedImageIndex !== null) {
      if (event.key === 'ArrowRight') {
        navigateImages(1);
      } else if (event.key === 'ArrowLeft') {
        navigateImages(-1);
      } else if (event.key === 'Escape') {
        closeModal();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedImageIndex]);

  const handleChange = () => {
    navigate('/gallery');
  };

  const experts = [
    {
      image: '/assets/events/hadees seminar_5.jpeg',
      position: "top"
    },
    {
      image: '/assets/events/hadees seminar_4.jpeg',
      position: "bottom"
    },
    {
      image: '/assets/events/hadees seminar_3.jpeg',
      position: "top"
    },
    {
      image: '/assets/events/hadees seminar_2.jpeg',
      position: "bottom"
    },
  ];

  const image = '/assets/events/hadees seminar.jpeg';

  return (
    <>
      <div className="w-full max-w-7xl mx-auto px-4 relative bg-white md:mt-10 md:pt-10 mt-14 flex flex-col  ">
        <button
          onClick={handleChange}
          className="sm:flex hidden font-medium absolute top-7 cursor-pointer z-[45] left-2 items-center gap-2 px-3 py-2 bg-gradient-to-r from-[#263238] to-[#37474F] text-white rounded-full shadow-lg hover:from-[#37474F] hover:to-[#263238] transition-all duration-300 transform "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-arrow-left"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 12l14 0" />
            <path d="M5 12l6 -6" />
            <path d="M5 12l6 6" />
          </svg>
          <span className="text-sm">Back</span>
        </button>

        <div className='absolute w-52 h-52 bg-orange-400 z-30 rounded-full blur-[130px]'></div>
        {/* <div className='absolute w-52 h-52 right-10 top-0 z-30 bg-orange-400 rounded-full blur-[130px] md:block hidden'></div> */}
        <div className='absolute w-52 h-52 right-[50%] top-[40%] z-30 bg-orange-400 rounded-full blur-[120px]'></div>
        <div className='absolute w-52 h-52 right-0 top-10 z-30 bg-orange-400 rounded-full blur-[130px] md:block hidden'></div>
        <div className='absolute w-52 h-52 left-0 bottom-0 z-30 bg-orange-400 rounded-full blur-[130px]'></div>

        <div >
          <h1 className="text-center w-full font-semibold md:text-3xl text-xl py-5 pt-8 md:pt-5 relative z-40 font-AndoraModernSerif">
            Hadees Seminar 2k24
          </h1>
          <p className="text-start md:text-center text-base text-black mb-12 max-w-3xl mx-auto relative z-40">
            Partner with our team of scientists, thinkers, and industry experts to take your talent strategy to the next level. We have consulted with the world's top minds and created innovative solutions talent.
          </p>
        </div>

        <div className="hidden md:block relative">
          <div className="grid grid-cols-4 md:px-20 gap-6 md:pt-10">
            {experts.map((expert, index) => (
              <div
                key={index}
                className={`transform z-40 relative ${expert.position === "top" ? "-translate-y-8" : "translate-y-8"}`}
              >
                <div
                  className="relative z-50 overflow-hidden rounded-lg shadow-md group cursor-pointer"
                  onClick={() => openImage(index)}
                >
                  <img
                    src={expert.image}
                    alt={`Gallery ${index + 1}`}
                    className="w-full h-64 z-50 relative object-cover transform transition-transform duration-300 group-hover:scale-110"
                  />
                  <div className="absolute z-50 inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center">
                    <p className="text-white font-semibold">View Image</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="md:hidden grid grid-cols-1 sm:grid-cols-2 gap-6">
          {experts.map((expert, index) => (
            <div key={index} className="bg-white rounded-lg overflow-hidden shadow-lg">
              <img
                src={expert.image}
                alt={`Expert ${index + 1}`}
                className="w-full h-64 object-cover relative z-40"
              />
            </div>
          ))}
        </div>

        <section className="text-center md:mb-10 mb-5 flex flex-wrap gap-5 md:gap-0 z-40 md:mt-20">
          <h1 className="text-center w-full font-semibold md:text-3xl text-xl md:py-12 pt-8 md:pt-5 font-AndoraModernSerif z-40 md:block hidden">
            Our Experts are like no other
          </h1>
          <div className='md:w-1/2 w-full z-40 mt-5 md:mt-0'>
            <img
              src={image}
              alt="Team Placeholder"
              className="rounded-lg w-full max-w-md mx-auto"
            />
          </div>
          <div className='md:w-1/2 w-full text-black pe-5 text-start'>
            <h1 className='md:text-2xl text-xl mb-2 font-AndoraModernSerif z-40'>Hadees Seminar</h1>
            <p className='z-40 md:text-base text-sm'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatibus cum tempora a minima reiciendis odio similique dolorum nemo esse fugit, sapiente, repellat ea eius sunt accusantium explicabo voluptatum sed obcaecati!</p>
            <br />

            <p className='z-40 text-base'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur veniam recusandae incidunt odit ad at adipisci, eaque laborum ipsam qui animi cumque culpa voluptatem quo quia excepturi saepe. Ad, atque. Porro architecto non, reprehenderit quam perspiciatis vitae. A suscipit voluptatum quasi, placeat commodi dolor possimus sapiente dolores id tenetur eveniet .</p>

          </div>

        </section>
      </div>

      {selectedImageIndex !== null && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
          <div className="relative">
            <button
              className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-full focus:outline-none"
              onClick={() => navigateImages(-1)}
            >
              &#8592;
            </button>
            <img
              src={experts[selectedImageIndex].image}
              alt="Selected"
              className="max-w-full max-h-screen rounded-lg"
            />
            <button
              className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white bg-gray-800 hover:bg-gray-700 px-3 py-2 rounded-full focus:outline-none"
              onClick={() => navigateImages(1)}
            >
              &#8594;
            </button>
            <button
              className="absolute top-20 right-4 text-white bg-red-500 hover:bg-red-600 px-4 py-2 rounded-full focus:outline-none"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default ExpertCards;
