import React, { useEffect } from 'react';
import Footer from '../home/footer';
import Events from './components/events_list';


export function GallaryPage() {

      useEffect(() => {
            window.scrollTo(0, 0);
          }, []);
          
    return (
        <>
            <div className='w-full flex flex-col items-center text-center min-h-screen relative pt-[60px] px-5 sm:px-10  md:px-20 lg:px-24 xl:px-36 overflow-hidden  '>
                <div className='max-w-[1400px] '>
                    <div className='bg-pumpkinToast-500 mt-[60px] w-[400px] h-[400px] absolute blur-[160px] z-0 top-[100px] '></div>
                    <div className='bg-pumpkinToast-500 mt-[60px] w-[400px] h-[400px] absolute blur-[160px] z-0 top-[150vh] right-0 '></div>
                    {/* <CarouselLatestImages /> */}
                    <div className='h-10'></div>
                    {/* <LatestPhotos /> */}
                    {/* <BestMomentSection /> */}
                    {/* <OurEvents /> */}
                </div>
                <Events />
                {/* <Success /> */}
            </div>
            <Footer />
        </>
    )
}
