import { IconBrandFacebookFilled, IconBrandLinkedin, IconBrandX } from '@tabler/icons-react'
import React from 'react'

export default function Footer() {
    return (
        <>
            <div className='bg-pumpkinToast-500 w-full md:px-20 px-5 py-4 z-40 relative'>
                <div className="flex flex-col md:flex-row items-center justify-between gap-3 bg-pumpkinToast-500 max-w-[1440px] max-md:mt-10 max-md:max-w-full">
                    <div className="flex md:gap-5 gap-2  max-w-[1195px]">
                        <div className="flex w-full md:max-w-[200px] sm:w-auto ">
                            <img src="/assets/whiteLogo.png" alt="logo" className='w-[40px] h-[40px] ' />

                            <div className=' flex flex-col gap-2'>
                                <div className="md:text-3xl text-base text-white font-lato flex gap-2 items-center justify-start">
                                    <h1 className='font-bold text-2xl text-white z-40'>DarulHadees</h1>
                                </div>
                                <div className=" text-xs  text-gray-100">
                                    Poocholamad, Kannattippadi (PO), Vengara
                                    (Via) Malappuram Dt. Kerala - 676 304, India
                                    <br/><span className=' font-bold'>Mob:</span> +91 8606391538 , +91 7736847386
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className=" text-xs  text-gray-100">
                        <span className="font-medium">@DarulHadees 2024</span>
                        <span>. All right reserved</span>
                    </div>
                    <div>
                        <div className=" md:text-base text-sm leading-none text-gray-100 max-md:mr-1 md:block hidden">
                            Social Media Links{" "}
                        </div>
                        <div className="flex gap-5 justify-between mx-3.5 mt-1.5 max-md:mx-2.5 text-white">
                            <IconBrandFacebookFilled />
                            <IconBrandLinkedin />
                            <IconBrandX />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
