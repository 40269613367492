import React, { useEffect, useState } from 'react';
import { HomeContent } from './homeContainer';
import Footer from './footer';
import { AboutUsSection } from './aboutUs';

export function HomePage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        window.scrollTo(0, 0);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='w-full text-center min-h-screen relative pt-[60px]'>
            {/* <div className='bg-[rgba(255,200,100,1)] mt-[60px] w-[300px] h-[300px] absolute blur-[160px] z-10 top-[100px]'></div> */}
            <div className="inset-0 top-0 left-0 absolute bg-black">
                <img 
                    src={isMobile ? '/assets/building_9_16.jpg' : '/assets/building.jpg'} 
                    alt='building' 
                    className='w-screen h-screen opacity-[0.7]' 
                />
            </div>

            <HomeContent />
            {/* <Success /> */}
            {/* <HowitWork /> */}
            {/* <Managment /> */}
            <AboutUsSection />
            {/* <SupportForm /> */}
            <Footer />
        </div>
    );
}
