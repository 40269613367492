import React, { useEffect } from 'react';
import { IconPhone, IconMapPin, IconBrandWhatsapp } from '@tabler/icons-react';
import Footer from '../home/footer';

const ContactNew = () => {

  useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

  return (
    <div className='relative'>
    <div className="w-full max-w-4xl mx-auto p-4 md:mt-20 mt-16">

    <div className='absolute w-52 h-52 bg-orange-400 left-0 z-30 rounded-full blur-[130px]'></div>
        {/* <div className='absolute w-52 h-52 right-10 top-0 z-30 bg-orange-400 rounded-full blur-[130px] md:block hidden'></div> */}
        <div className='absolute w-52 h-52 right-[40%] top-[50%]  z-30 bg-orange-400 rounded-full blur-[120px]'></div>
        <div className='absolute w-52 h-52 right-0 top-10 z-30 bg-orange-400 rounded-full blur-[130px] md:block hidden'></div>
        {/* <div className='absolute w-52 h-52 left-0 bottom-0 z-30 bg-orange-400 rounded-full blur-[130px]'></div> */}

      {/* Header Section */}
      <div className="text-center md:mb-8 mb-5">
        <h1 className="md:text-3xl text-xl font-bold mb-2 text-[#2a1273] font-AndoraModernSerif">Contact Us</h1>
        <p className="text-gray-600 text-base">Any questions or remarks? Just write us a message!</p>
      </div>

      {/* Form Section */}
      <div className="mb-12">
        <form className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 z-40 relative">
          
            <div>
              <label htmlFor="name" className="block text-gray-600 mb-1">Name</label>
              <input
                type="text"
                id="name"
                className="w-full p-2 rounded-md bg-gray-100 z-40 relative"
                placeholder="Enter your Name"
              />
            </div>
            <div>
              <label htmlFor="email" className="block text-gray-600 mb-1">Email</label>
              <input
                type="email"
                id="email"
                className="w-full p-2 rounded-md bg-gray-100"
                placeholder="Enter a valid email address"
              />
            </div>
            <div className='md:col-span-2'>
                <textarea name="" className='w-full p-2 bg-gray-100 rounded-md' placeholder='enter your Message here...' id=""></textarea>
              {/* <label htmlFor="name" className="block text-gray-600 mb-1">Name</label>
              <input
                type="text"
                id="name"
                className="w-full p-2 rounded-md bg-gray-100"
                placeholder="Enter your Name"
              /> */}
            </div>
          </div>
          <button className="w-full bg-pumpkinToast-500 text-white font-AndoraModernSerif py-2 rounded-md hover:bg-pumpkinToast-400 transition-colors relative z-40">
            Submit
          </button>
        </form>
      </div>

      {/* Info Cards Section */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:mb-3 relative z-40">

    

        <div className="px-6 text-center">
          <div className="flex justify-center mb-4">
            <div className="p-4 bg-pumpkinToast-500  rounded-full">
              <IconPhone className="w-6 h-6 text-white" />
            </div>
          </div>
          <h2 className="text-sm font-AndoraModernSerif font-bold mb-2">OUR CONTACT NO</h2>
          <div className="text-gray-600">
            <p className=''>+91 8606391538</p>
            <p>+91 7736847386</p>
          </div>
        </div>

        <div className="px-6 text-center">
          <div className="flex justify-center mb-4">
            <div className="p-4 bg-pumpkinToast-500 rounded-full">
              <IconMapPin className="w-6 h-6 text-white" />
            </div>
          </div>
          <h2 className="text-sm font-bold mb-2 font-AndoraModernSerif">OUR OFFICE LOCATION</h2>
          <div className="text-gray-600">
            <p className='text-sm'>Poocholamad, Kannattippadi (PO),</p>
            <p  className='text-sm'>Vengara (Via) Malappuram Dt.</p>
            <p  className='text-sm'>Kerala - 676 304, India</p>
          </div>
        </div>

        <div className="px-6 text-center">
          <div className="flex justify-center mb-4">
            <div className="p-4 bg-pumpkinToast-500  rounded-full">
              <IconBrandWhatsapp className="w-6 h-6 text-white" />
            </div>
          </div>
          <h2 className="text-sm font-AndoraModernSerif font-bold mb-2">WHATSAPP</h2>
          <div className="text-gray-600">
          <p className=''>+91 8606391538</p>
          <p>+91 7736847386</p>
            {/* <p>Workouts</p> */}
          </div>
        </div>

       

        
      </div>
    </div>
    <Footer/>
    </div>
  );
};

export default ContactNew;