import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Events() {
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top when the route changes
    window.scrollTo(0, 0);
  }, []);

  const events = [
    { id: 1, name: "Common events", description: "This is the first event. here is the information about data science in the growth current world", image: "/assets/events/common_event.jpeg" },
    { id: 2, name: "Hadees seminar", description: "This is the second event. here is the information about data science in the growth current world", image: "/assets/events/hadees seminar.jpeg" },
    { id: 3, name: "Common events", description: "This is the first event. here is the information about data science in the growth current world", image: "/assets/events/common_event.jpeg" },
  ];

  const handleChange = (data) => {
    // navigate(`/events/${data}`)
    navigate(`/events-details`)
  }

  return (
    <>
      <div className="relative">

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 py-5 ">
          {events.map((event) => (
            <div
              key={event.id}
              onClick={() => handleChange(event.id.toString())}
              className="bg-white hover:scale-[0.95] z-40 cursor-pointer transition-all shadow-2xl rounded-lg overflow-hidden border border-gray-200"
            >
              <img
                src={event.image}
                alt={event.name}
                className="w-full h-52 object-cover"
              />
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800 text-start font-AndoraModernSerif">{event.name}</h3>
                <p className="text-gray-600 mt-2 text-sm text-start ">{event.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}