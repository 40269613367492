import React, { useState, useEffect } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import {
  IconHome,
  IconHomeCog,
  IconBook,
  IconSchool,
  IconBrandGooglePhotos,
  IconAddressBook,
} from '@tabler/icons-react';

export function NavBar() {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [activeLink, setActiveLink] = useState('home');
  const [navbarBg, setNavbarBg] = useState('bg-transparent');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const currentPath = location.pathname;

      if (currentPath === '/home') {
        setNavbarBg(scrollY > 0 ? 'bg-white shadow-lg' : 'bg-transparent');
      } else {
        setNavbarBg('bg-white shadow-lg');
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call to set the background on load
    return () => window.removeEventListener('scroll', handleScroll);
  }, [location.pathname]);

  const toggleSidebar = () => {
    setOpenSidebar(!openSidebar);
  };

  const closeSidebar = () => {
    setOpenSidebar(false);
  };

  const links = [
    { name: 'Home', path: 'home', icon: <IconHome className="h-5 w-5" /> },
    {
      name: 'About',
      icon: <IconHomeCog className="h-5 w-5" />,
      onclick: () => {
        setActiveLink('about');
        const aboutUs = document.getElementById('about_us_section');
        if (aboutUs) {
          aboutUs.scrollIntoView({ behavior: 'smooth' });
        } else {
          navigate('/');
          setTimeout(() => {
            const aboutUs = document.getElementById('about_us_section');
            if (aboutUs) {
              aboutUs.scrollIntoView({ behavior: 'smooth' });
            }
          }, 100);
        }
      },
      ignoreLink: true,
    },
    { name: 'Library', path: 'library', icon: <IconBook className="h-5 w-5" /> },
    { name: 'Courses', path: 'courses', icon: <IconSchool className="h-5 w-5" /> },
    { name: 'Gallery', path: 'gallery', icon: <IconBrandGooglePhotos className="h-5 w-5" /> },
    { name: 'Contact', path: 'contact', icon: <IconAddressBook className="h-5 w-5" /> },
  ];

  return (
    <>
      {/* Navbar for screens above 700px */}
      <div
        className={`hidden sm:flex w-full h-[60px] z-[99] ${navbarBg} justify-between lg:px-28 md:px-10 px-5 items-center fixed top-0 transition-colors duration-300`}
      >
        <div
          className="logo font-AndoraModernSerif text-2xl flex gap-1 items-center cursor-pointer"
          onClick={() => {
            setActiveLink('home');
            navigate('/home');
          }}
        >
          <img src="/assets/darulLogo.png" alt="logo" className="w-[50px] h-[50px]" />
          <p className="font-AndoraModernSerif mt-4 text-[#2a1273]">DarulHadees</p>
        </div>
        <div className="flex lg:gap-10 gap-4 md:gap-5 font-AndoraModernSerif">
          {links.map(({ name, path, onclick, ignoreLink }) => {
            if (ignoreLink) {
              return (
                <p
                  key={name}
                  onClick={onclick}
                  className={`cursor-pointer font-semibold ${
                    activeLink === 'about' ? 'text-pumpkinToast-400' : 'text-black'
                  }`}
                >
                  {name}
                </p>
              );
            } else {
              return (
                <NavLink
                  key={path}
                  to={path ? `/${path}` : ''}
                  className={({ isActive }) =>
                    ` ${
                      isActive || activeLink === name.toLowerCase()
                        ? 'text-pumpkinToast-400 font-semibold'
                        : 'text-black font-semibold'
                    }`
                  }
                  onClick={() => setActiveLink(name.toLowerCase())}
                >
                  <p className="">{name}</p>
                </NavLink>
              );
            }
          })}
        </div>
      </div>

      {/* Sidebar and Menu Icon for screens below 700px */}
      <div className="flex sm:hidden fixed top-0 w-full h-[60px] bg-white items-center justify-between px-5 z-[99] shadow-lg">
        <div
          className="logo font-semibold text-2xl flex gap-1 items-end cursor-pointer"
          onClick={() => {
            setActiveLink('home');
            navigate('/home');
          }}
        >
          <img src="/assets/darulLogo.png" alt="logo" className="w-[50px] h-[50px]" />
          <p className="text-xl pb-1 font-AndoraModernSerif text-[#2a1273]">DarulHadees</p>
        </div>
        <button onClick={toggleSidebar} style={{ backgroundColor: '#DD9356' }} className="p-2 rounded">
          <Bars3Icon className="h-6 w-6 text-white" />
        </button>
      </div>

      {/* Custom Sidebar */}
      <div
        className={`fixed top-0 left-0 h-full w-[250px] bg-white shadow-lg z-50 transform ${
          openSidebar ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex flex-col gap-8 px-7 py-3">
          {/* Logo Section */}
          <div
            className="flex items-end gap-2 cursor-pointer"
            onClick={() => {
              setActiveLink('home');
              navigate('/home');
            }}
          >
            <img src="/assets/darulLogo.png" alt="logo" className="w-[40px] h-[40px]" />
            <p className="font-semibold text-xl font-AndoraModernSerif text-[#2a1273]">DarulHadees</p>
          </div>

          {/* Links Section */}
          <div className="flex flex-col gap-5">
            {links.map(({ name, path, icon, onclick, ignoreLink }) => {
              if (ignoreLink) {
                return (
                  <p
                    key={name}
                    onClick={() => {
                      closeSidebar();
                      onclick();
                    }}
                    className={`cursor-pointer flex items-center gap-3 w-full h-[40px] px-4 ${
                      activeLink === 'about'
                        ? 'text-white rounded-md bg-pumpkinToast-500'
                        : 'text-black'
                    }`}
                  >
                    {icon}
                    {name}
                  </p>
                );
              } else {
                return (
                  <NavLink
                    key={path}
                    to={`/${path}`}
                    className={({ isActive }) =>
                      isActive || activeLink === name.toLowerCase()
                        ? 'text-white rounded-md bg-pumpkinToast-500'
                        : 'text-black'
                    }
                    onClick={() => {
                      closeSidebar();
                      setActiveLink(name.toLowerCase());
                    }}
                  >
                    <div className="flex items-center gap-3 w-full h-[40px] px-4">
                      {icon}
                      <p className="">{name}</p>
                    </div>
                  </NavLink>
                );
              }
            })}
          </div>
        </div>
      </div>

      {/* Backdrop Blur */}
      {openSidebar && (
        <div
          onClick={closeSidebar}
          className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 backdrop-blur-md z-40 transition-opacity duration-300 ease-in-out"
        />
      )}
    </>
  );
}
