import React from 'react'
import { IconPointFilled } from '@tabler/icons-react';
import { ButtonEdge } from '../../components/buttonsOg';
import { CourseCategoryCarousel } from './components/courceCard';

export function PopularCourse() {
    return (
        <div className='w-full  text-center relative z-10'>
            {/* POPULAR COURSES HEADING */}
            <div className=' mx-auto w-min relative'>
                <h2 className="whitespace-nowrap font-bold font-AndoraModernSerif tracking-tighter leading-tight text-[#2a1273] max-w-[340px] mx-auto -rotate-[0.174deg] mt-14 md:text-3xl text-xl">
                    Popular Courses
                </h2>
                <div className=' absolute -bottom-1 -right-9'>
                    <svg xmlns="http://www.w3.org/2000/svg" height="10" viewBox="0 0 178 10" fill="none" className='md:w-[178px] w-[120px]'>
                        <path d="M1 8.30692C49.5338 0.217925 116.717 -0.456198 177.834 8.30692" stroke="black" stroke-width="2.24694" />
                    </svg>
                </div>
            </div>
            {/* BUTTON TABS */}
            {/* <div className='  '>
                <div className=' flex gap-8  max-w-[100vw] px-10 overflow-auto no-scrollbar w-min mx-auto   mt-14'>
                    <ButtonEdge variant='primary' text='All programme' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='Ui/Ux Design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />
                    <ButtonEdge variant='outlined' text='program  design' className='min-w-[160px]' />

                </div>
            </div> */}
            {/* COURSE CARDS*/}
            <CourseCategoryCarousel />

        </div>
    )
}
